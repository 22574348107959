const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer__paragraph">Made with React.js and lots of coffee ☕</p>
      <span className="meta-info">
        <span className="meta-info__item"><a href="https://www.linkedin.com/in/skylar-dryden-29b914182/" target="_blank" className="meta-info__link">LinkedIn</a></span> 
        <span className="meta-info__item meta-info__item--dot">•</span> 
        <span className="meta-info__item"><a href="https://github.com/Staaikies" target="_blank" className="meta-info__link">Github Projects</a></span>
      </span>
    </footer>
  )
}

export default Footer;